import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import inventory from './appData'
import settings from './settings'
import library from './library'
import { $api } from "bh-mod";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		inventory,
		library,
	},
	state: {
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		loading: false,
		theApp: {},
		fileStructure: [],
		realtors: []
	},
	mutations: {
		SET_REALTORS(state, data) {
			state.realtors = data;
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		LOAD(state, is = null) {
			is !== null ? state.loading = Boolean(is) : state.loading = !state.loading
		},
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		UPDATE_INSTANCE: (state, data) => {
			state.instance = { ...state.instance, ...data }
		}
	},
	getters: {
		hasWorksheetAppAccess(state) {
			return !!state.instance?.apps?.find(app => app.subdomain === "worksheets");
		},
		hasReservationAccess(state) {
			return state?.instance?.package?.options?.access?.reservation || false;
		},
	},
	actions: {
		async FETCH_REALTORS({ state, commit }) {
			if (Vue.prototype.$userType === 'agent') {
				return
			}
			let instanceId = state.instance && state.instance.id || "";
			let { data } = await $api.get(
				"/instances/" + instanceId + "/users"
			);
			if (data.users && data.users.length) {
				let users = JSON.parse(JSON.stringify(data.users));
				users = users.filter(u => u.role && u.role.name === 'bh_agent').map(u => u.user);
				commit("SET_REALTORS", users);
			}
		},
	},
})
